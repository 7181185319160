import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Avatar from '../images/avatar.jpeg';

const GlobalStyle = createGlobalStyle`
    html {
        margin: 0;
        padding: 0;
        height: 100%;
        font-family: Inter, Roobert, "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    body {
        margin: 0;
        padding: 0;
        height: 100%;
        background: #011063;
        background: -webkit-linear-gradient(top left, #011063, #D523D7);
        background: -moz-linear-gradient(top left, #011063, #D523D7);
        background: linear-gradient(to bottom right, #011063, #D523D7);
    }
`;

const CardContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 1em;
`;

const Card = styled.div`
    padding: 1em;
    background-color: black;
    border-radius: 6px;
    max-width: 800px;
    color: white;
    text-align: center;
`;

const HeaderImage = styled.img`
    max-width: min(50%, 300px);
    border-radius: 50%;
`;

const SocialMediaDiv = styled.div`
    padding: 15px;
    margin: 1em;
    border-radius: 8px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 24px;

    box-shadow:
        inset 0 0 5px #fff,
        inset 5px 0 10px #f0f,
        inset -5px 0 10px #f0f,
        0px 0 10px #f0f;

    & a {
        color: inherit;
        text-decoration: none;
    }
`;

const SocialMedia = ({ URL, Label }) => {
    const onClick = event => {
        event.preventDefault();
        window.open(URL);
    };

    return (
        <SocialMediaDiv onClick={onClick}>
            <a href={URL} target={'_blank'} rel={'noreferrer'}>{Label}</a>
        </SocialMediaDiv>
    );
};

export default function IndexPage() {
    return (
        <>
            <GlobalStyle />
            <CardContainer>
                <Card>
                    <HeaderImage src={Avatar} alt="Evie" />
                    <h2>@evolutionofevie</h2>
                    <SocialMedia
                        URL={'https://twitch.tv/evolutionofevie'}
                        Label={'Twitch'}
                    />
                    <SocialMedia
                        URL={'https://twitter.com/evolutionofevie'}
                        Label={'Twitter'}
                    />
                    <SocialMedia
                        URL={'https://www.instagram.com/evolutionofevie/'}
                        Label={'IG'}
                    />
                    <SocialMedia
                        URL={'https://discord.gg/NakUTUR'}
                        Label={'Discord'}
                    />
                    <SocialMedia
                        URL={'https://www.amazon.com/hz/wishlist/ls/1ETBRRRYG5HPJ?ref_=wl_share'}
                        Label={'Amazon Wishlist'}
                    />
                </Card>
            </CardContainer>
        </>
    );
};